import { Pipe, PipeTransform } from '@angular/core';

const statusTexts = {
  finished: 'Finished',
  pushing_code: 'Pushing code',
  generating_terraform: 'Generating terraform',
  error: 'Error',
};

const generateStatusText = status =>
  status.charAt(0).toUpperCase() +
  status
    .slice(1)
    .replace(/-|_/g, ' ')
    .replace(/ event$/, '');

@Pipe({
  name: 'readableStatus',
})
export class ReadableStatusPipe implements PipeTransform {
  public transform(status: string): string {
    if (!status) {
      return '';
    }
    return statusTexts[status] || generateStatusText(status);
  }
}
