import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { SnackBarService } from '@core/services/snack-bar.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error) {
    // HttpErrorResponse handled in HttpErrorInterceptor
    // throwError wraps the HttpErrorResponse in error.rejection
    if (!(error.rejection instanceof HttpErrorResponse) && !(error instanceof HttpErrorResponse)) {
      this.displayError(error);
    }
    // Rethrow the error for other processing
    throw error;
  }

  displayError(error) {
    const errorMessage = this.getMessage(error);
    const snackBarService = this.injector.get(SnackBarService);
    snackBarService.error(errorMessage);
  }

  getMessage(error) {
    // CLIENT-SIDE ERRORS
    // Typescript/DOM error events OR Node.js errors
    if ((error instanceof ErrorEvent || error instanceof Error) && error.message) {
      return `An exception in the browser has occured. \nMessage: ${
        error.message
      } \nTry refreshing the page. Any unsaved changes will be lost.`;
    }

    // Pure Javascript exceptions
    return `An exception in the browser has occured. \nMessage: ${error} \nTry refreshing the page. Any unsaved changes will be lost.`;
  }
}
