import { Injectable } from '@angular/core';
import { DialogService } from '@shared/services/dialog/dialog.service';
import { ConfirmationDialogComponent } from '@shared/components/confirm-dialog/confirmation-dialog.component';
import { Observable } from 'rxjs';
import { ConfirmationDialogConfig } from '@shared/interfaces/confirmation-dialog-config';
import { OverlayConfig } from '@angular/cdk/overlay';
import { AbstractControl } from '@angular/forms';
import { DialogContainer } from '@shared/services/dialog/dialog-container';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {
  private change = false;

  constructor(private dialogService: DialogService) {}

  public open(config: ConfirmationDialogConfig, overlayConfig?: OverlayConfig): Observable<boolean> {
    // Here we set wrap to false, in the additional parameter, because we don't want the confirmation window to be wrapped twice.
    const container: DialogContainer<ConfirmationDialogComponent> = this.dialogService.open(
      ConfirmationDialogComponent,
      overlayConfig,
      config,
      {
        wrap: false,
      }
    );

    return container.componentRef.instance.afterClose;
  }

  public subscribeForChanges(form: AbstractControl) {
    form.valueChanges.subscribe(value => {
      this.setChange(true);
    });
  }

  public unsavedChangesConfirmation(callback: Function, rejectCallback?: Function) {
    if (this.change) {
      this.open({
        title: 'Please confirm your action',
        message: 'You have unsaved changes. Are you sure you want to continue ?',
      }).subscribe(confirmed => {
        if (!confirmed) {
          if (rejectCallback) {
            rejectCallback();
          }
          return;
        }
        callback();
        this.change = false;
      });
    } else {
      callback();
    }
  }

  public setChange(change: boolean) {
    this.change = change;
  }

  public hasChange(): boolean {
    return this.change;
  }
}
