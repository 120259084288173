import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({ selector: '[alpMiddleClick]' })
export class MiddleClickNavDirective {
  // The input can be either a concrete href, or some relative route,
  // which can be used as a suffix for the current route.
  @Input('alpMiddleClick') public middleClick: {
    href?: string;
    relativeRoute?: any[];
  };

  constructor(private el: ElementRef) {}

  @HostListener('auxclick', ['$event'])
  public onClick(mouseEvent) {
    if (mouseEvent.which === 2) {
      if (this.middleClick.href) {
        window.open(this.middleClick.href);
      } else if (this.middleClick.relativeRoute) {
        window.open(`${window.location.href}/${this.middleClick.relativeRoute}`);
      } else {
        console.warn(`No href or relativeRoute was provided for alpMiddleClick directive.`);
      }

      mouseEvent.preventDefault();
    }
  }
}
