import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiResponse } from '@core/interfaces/api-response';
import { Deployment } from '@core/interfaces/deployment';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class DeploymentService {
  private url = `${environment.apiEndpoint}/deployments`;

  constructor(private httpClient: HttpClient) {}

  public list(
    workflowId: number,
    workflowVersion: number,
    page: number = 0,
    limit: number = 5,
    filter: string = undefined
  ): Observable<ApiResponse<Deployment[]>> {
    let params = new HttpParams()
      .append('workflowId', workflowId.toString())
      .append('offset', (page * limit).toString())
      .append('limit', limit.toString());

    if (workflowVersion) {
      params = params.append('workflowVersion', workflowVersion.toString());
    }
    return this.httpClient.get<ApiResponse<Deployment[]>>(this.url, { params: params });
  }

  public get(id: number): Observable<Deployment> {
    return this.httpClient.get<Deployment>(`${this.url}/${id}`);
  }

  public create(deployment: Deployment): Observable<Deployment> {
    return this.httpClient.post<Deployment>(this.url, deployment);
  }

  public downloadBundleUrl(deploymentId: number): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/${deploymentId}/bundle`);
  }
}
