import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseParser',
})
export class CamelCaseParserPipe implements PipeTransform {
  public transform(camelCaseValue: string, args?: any): string {
    if (!camelCaseValue) {
      return '';
    }
    return camelCaseValue.charAt(0).toUpperCase() + camelCaseValue.slice(1).replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  }
}
