import { ComponentRef, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ComponentTypeToBeCreatedInsideModalWrapper, DialogContent } from './dialog-content';
import { ComponentType } from '@angular/cdk/portal/typings/portal';
import { DialogConfig } from './dialog-config';
import { DialogContainer } from './dialog-container';
import { ModalWrapperComponent } from '@shared/components/modal-wrapper/modal-wrapper.component';

@Injectable()
export class DialogService {
  constructor(private injector: Injector, private overlay: Overlay) {}

  public open<T>(
    component: ComponentType<T>,
    config: OverlayConfig = {},
    content?,
    additionalOptions = {
      wrap: true,
    }
  ): DialogContainer<any> {
    // create configuration from default and given configuration
    const overlayConfig: DialogConfig = this.getOverlayConfig(config);
    overlayConfig.content = content;

    const overlayRef = this.overlay.create(overlayConfig);

    const componentRef: ComponentRef<T | ModalWrapperComponent> = additionalOptions.wrap
      ? this.attachDialogContainerWithWrapper(component, overlayRef, overlayConfig)
      : this.attachDialogContainer(component, overlayRef, overlayConfig);

    return new DialogContainer(overlayRef, componentRef);
  }

  private attachDialogContainer<T>(component: ComponentType<T>, overlayRef: OverlayRef, config: any): ComponentRef<T> {
    const injector = this.createInjector(config, overlayRef);
    const containerPortal = new ComponentPortal(component, null, injector);
    return overlayRef.attach(containerPortal);
  }

  private attachDialogContainerWithWrapper<T>(
    component: ComponentType<T>,
    overlayRef: OverlayRef,
    config: any
  ): ComponentRef<ModalWrapperComponent> {
    const injector = this.createInjector(config, overlayRef, component);
    const overlayConfig = this.getOverlayConfig(config);
    this.overlay.create(overlayConfig);
    const containerPortal = new ComponentPortal(ModalWrapperComponent, null, injector);

    return overlayRef.attach(containerPortal);
  }

  private createInjector(config: DialogConfig, overlayRef: OverlayRef, component?: any): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(OverlayRef, overlayRef);

    if (component) {
      injectionTokens.set(ComponentTypeToBeCreatedInsideModalWrapper, component);
    }
    injectionTokens.set(DialogContent, config.content || {});
    injectionTokens.set(DialogContent, config.content || {});

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: any): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const defaultConfig = {
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    };

    return { ...defaultConfig, ...config };
  }
}
