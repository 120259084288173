import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class SnackBarService {
  constructor(private snackBar: MatSnackBar, private zone: NgZone) {}

  public info(message: string) {
    if (!message) {
      return;
    }
    this.snackBar.open(message, null, {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 2000,
    });
  }

  public warning(message: string) {
    this.openSnack(message, 'warning');
  }

  public error(message: string) {
    this.openSnack(message, 'error');
  }

  private openSnack(message: string, panelClass: string) {
    if (!message) {
      return;
    }
    // Zone needed by global error handler to position correctly the snack bar
    this.zone.run(() =>
      this.snackBar.open(message, 'OK', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        panelClass: panelClass,
      })
    );
  }
}
