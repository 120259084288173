<h1>{{ title }}</h1>

<div class="content">

  <div *ngIf="!useMessageLines && message">{{ message }}</div>
  <div *ngIf="useMessageLines && messageLines">
    <div *ngFor="let messageLine of messageLines">{{messageLine}}</div>
  </div>

  <div class="message" *ngIf="message"></div>
  <div class="buttons">
    <a *ngIf="showCancel" (click)="close()" class="pointer">Cancel</a>
    <alp-button (click)="confirm()" prefixIcon="fas fa-check-circle">{{ confirmText }}</alp-button>
  </div>
</div>
