import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog.service';

@Directive({ selector: '[href]' })
export class UnsavedChangesDirective {

    @Input() public href: string | string[];

    constructor(private el: ElementRef, private confirmationService: ConfirmationDialogService, private router: Router) { }

    @HostListener('click', ['$event'])
    public onClick(event) {
        event.preventDefault();
        if (this.el.nativeElement.getAttribute('target') === '_blank') {
            this.navigateExternal();
        } else {
            this.navigateLocally();
        }
    }

    private navigateExternal() {
        if (typeof this.href === 'string') {
            this.confirmationService.unsavedChangesConfirmation(() => window.location.href = <string>this.href);
        }
    }

    private navigateLocally() {
        if (typeof this.href === 'string') {
            this.confirmationService.unsavedChangesConfirmation(() => this.router.navigate([this.href]));
        } else {
            this.confirmationService.unsavedChangesConfirmation(() => this.router.navigate(<string[]>this.href));
        }
    }
}
