import { Pipe, PipeTransform } from '@angular/core';
import { Environment } from '@core/interfaces/environment';

@Pipe({
  name: 'readableEnv',
})
export class ReadableEnvPipe implements PipeTransform {
  constructor() {}

  public transform(env: string, args?: any): string {
    const envDef = Environment.All.find(e => e.value === env);
    return envDef != null ? envDef.name : env;
  }
}
