import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReadableStatusPipe } from './readable-status.pipe';
import { ReadableEnvPipe } from './readable-env.pipe';
import { CamelCaseParserPipe } from './camel-case-parser.pipe';

const PIPES = [ReadableEnvPipe, ReadableStatusPipe, CamelCaseParserPipe];
@NgModule({
  declarations: [...PIPES],
  entryComponents: [],
  exports: [...PIPES],
  imports: [CommonModule],
})
export class PipesModule {}
