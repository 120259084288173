import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiResponse } from '@core/interfaces/api-response';
import { Observable } from 'rxjs';
import { Workflow } from '@core/models/workflow';
import { environment } from '../../../environments/environment';
import { WorkflowVersionForComponentTemplateQuery } from '@core/models/workflow-version';
import { RemoteWorkflowInformation } from '@core/models/remote-workflow-information';

@Injectable()
export class WorkflowService {
  private url = `${environment.apiEndpoint}/workflows`;

  constructor(private httpClient: HttpClient) {}

  public list(queryParams: {
    projectId?: number;
    limit?: number;
    offset?: number;
    mustBeLinkable?: boolean;
  }): Observable<ApiResponse<Workflow[]>> {
    let params = new HttpParams();
    if (queryParams.projectId) {
      params = params.append('projectId', queryParams.projectId.toString());
    }
    if (queryParams.limit) {
      params = params.append('limit', queryParams.limit.toString());
    }
    if (queryParams.offset) {
      params = params.append('offset', queryParams.offset.toString());
    }
    if (queryParams.mustBeLinkable) {
      params = params.append('mustBeLinkable', queryParams.mustBeLinkable.toString());
    }
    return this.httpClient.get<ApiResponse<Workflow[]>>(this.url, { params: params });
  }

  public get(id: number): Observable<Workflow> {
    return this.httpClient.get<Workflow>(`${this.url}/${id}`);
  }

  public getRemoteWorkflowInformation(workflowIds: number[]): Observable<RemoteWorkflowInformation[]> {
    return this.httpClient.get<RemoteWorkflowInformation[]>(
      `${this.url}/get-remote-information?workflowIds=${workflowIds}`
    );
  }

  public update(id: number, workflow: Workflow): Observable<ApiResponse<Workflow>> {
    return this.httpClient.patch<ApiResponse<Workflow>>(`${this.url}/${id}`, workflow);
  }

  public create(workflow: Workflow): Observable<ApiResponse<Workflow>> {
    return this.httpClient.post<ApiResponse<Workflow>>(this.url, workflow);
  }

  public delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }

  public listWorkflowsForComponentTemplate(
    componentTemplateId: number,
    componentTemplateVersion?: string
  ): Observable<ApiResponse<WorkflowVersionForComponentTemplateQuery[]>> {
    let params = new HttpParams().append('componentTemplateId', componentTemplateId.toString());
    if (componentTemplateVersion) {
      params = params.append('componentTemplateVersion', componentTemplateVersion);
    }
    return this.httpClient.get<ApiResponse<WorkflowVersionForComponentTemplateQuery[]>>(
      `${this.url}/by-component-template`,
      { params: params }
    );
  }
}
