import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observer, Observable, forkJoin } from 'rxjs';

@Injectable()
export class AppLoader implements CanActivate {
  constructor() {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((responseObserver: Observer<boolean>) => {
      forkJoin([
        new Observable<boolean>((obs: Observer<boolean>) => {
          obs.next(true);
          obs.complete();
        }),
      ]).subscribe(null, null, () => {
        responseObserver.next(true);
        responseObserver.complete();
      });
    });
  }
}
