import {
  BreadCrumbModule,
  ButtonModule,
  CheckboxModule,
  DropdownModule,
  HeroMenuModule,
  TabModule,
  TopMenuModule,
} from '@alpiq/ui-elements';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule, TableModule } from '@astron/k3-table';
import { MatSnackBarModule } from '@angular/material';
import { BLOCK_DEFAULT_GLOBAL_COMPONENT, BLOCK_DEFAULT_SPECIFIED_COMPONENT, BlockModule } from '@efk3/angular-block';
import { GlobalLoadingIndicatorComponent } from '@shared/components/loading-indicator/global-loading-indicator.component';
import { LoadingIndicatorComponent } from '@shared/components/loading-indicator/loading-indicator.component';
import { ConfirmationDialogComponent } from '@shared/components/confirm-dialog/confirmation-dialog.component';
import { UnsavedChangesDirective } from '@shared/directives/unsaved-change.directive';
import { DialogService } from './services/dialog/dialog.service';
import { MiddleClickNavDirective } from '@shared/directives/middle-click-nav.directive';
import { ModalWrapperComponent } from '@shared/components/modal-wrapper/modal-wrapper.component';

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  ButtonModule,
  HeroMenuModule,
  TopMenuModule,
  TabModule,
  BreadCrumbModule,
  CheckboxModule,
  TableModule,
  PaginationModule,
  DropdownModule,
  OverlayModule,
  MatSnackBarModule,
  BlockModule,
];

const DIRECTIVES = [UnsavedChangesDirective, MiddleClickNavDirective];

@NgModule({
  imports: MODULES,
  exports: [...MODULES, ...DIRECTIVES],
  providers: [DialogService],
  declarations: [
    GlobalLoadingIndicatorComponent,
    LoadingIndicatorComponent,
    ConfirmationDialogComponent,
    ModalWrapperComponent,
    ...DIRECTIVES,
  ],
  entryComponents: [
    ModalWrapperComponent,
    GlobalLoadingIndicatorComponent,
    LoadingIndicatorComponent,
    ConfirmationDialogComponent,
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: BLOCK_DEFAULT_GLOBAL_COMPONENT, useValue: GlobalLoadingIndicatorComponent },
        { provide: BLOCK_DEFAULT_SPECIFIED_COMPONENT, useValue: LoadingIndicatorComponent },
      ],
    };
  }
}
