import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiResponse } from '@core/interfaces/api-response';
import { ComponentTemplate } from '@core/interfaces/component-template';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { TemplateVersion } from '@core/interfaces/template-version';

@Injectable()
export class ComponentTemplateService {
  private readonly VERSIONS = 'versions';
  private readonly url = `${environment.apiEndpoint}/component-templates`;

  constructor(private httpClient: HttpClient) {}

  public list(
    page: number = 0,
    limit: number = 5,
    name?: string,
    generic?: boolean
  ): Observable<ApiResponse<ComponentTemplate[]>> {
    let params = new HttpParams().append('offset', (page * limit).toString()).append('limit', limit.toString());

    if (name) {
      params = params.append('name', name);
    }
    if (generic != null) {
      params = params.append('isGeneric', generic.toString());
    }

    //console.log(params);

    return this.httpClient.get<ApiResponse<ComponentTemplate[]>>(this.url, { params: params });
  }

  public get(id: number): Observable<ComponentTemplate> {
    const componentTemplateObservable = this.httpClient.get<ComponentTemplate>(`${this.url}/${id}`);

    return componentTemplateObservable.pipe(
      map(componentTemplate => {
        const convertedSelectedVersion = this.convertListAndMapTemplateVariableTypes(componentTemplate.selectedVersion);
        const convertedAvailableVersions = componentTemplate.availableVersions.map(templateVersion =>
          this.convertListAndMapTemplateVariableTypes(templateVersion)
        );

        return {
          ...componentTemplate,
          selectedVersion: convertedSelectedVersion,
          availableVersions: convertedAvailableVersions,
        };
      })
    );
  }

  public save(component: ComponentTemplate): Observable<ComponentTemplate> {
    return this.httpClient.post<ApiResponse<ComponentTemplate>>(this.url, component).pipe(map(r => r.payload));
  }

  public listVersions(id: number, includeDeleted: boolean = false, tfVersion = null): Observable<TemplateVersion[]> {
    const params: { includeDeleted: boolean; tfVersion?: string } = {
      includeDeleted: includeDeleted,
    };

    if (tfVersion) {
      params.tfVersion = tfVersion;
    }

    return this.httpClient
      .get<ApiResponse<TemplateVersion[]>>(`${this.url}/${id}/${this.VERSIONS}`, <Object>params)
      .pipe(map(r => r.payload.map(templateVersion => this.convertListAndMapTemplateVariableTypes(templateVersion))));
  }

  public listAllVersions(ids: Array<number>, tfVersion = null): Observable<TemplateVersion[]> {
    let params = new HttpParams()
      .append('componentTemplateIds', JSON.stringify(ids))
      .append('offset', '0')
      .append('limit', '10000');

    if (tfVersion) {
      params = params.append('tfVersion', tfVersion);
    }

    return this.httpClient
      .get<ApiResponse<TemplateVersion[]>>(`${this.url}/${this.VERSIONS}`, { params: params })
      .pipe(map(r => r.payload.map(templateVersion => this.convertListAndMapTemplateVariableTypes(templateVersion))));
  }

  public getVersion(id: number, gitIdentifier: string): Observable<TemplateVersion> {
    // We need to check that a gitIdentifier was actually provided.
    // If not, we would actually end up calling the list versions endpoint resulting in some hard to understand errors
    if (gitIdentifier === '') {
      throw new Error('No gitIdentifier specified when getting component template version');
    }

    const observable = this.httpClient.get<TemplateVersion>(
      `${this.url}/${id}/${this.VERSIONS}/${gitIdentifier.replace(/\//g, '%2F')}`
    );

    return observable.pipe(map(templateVersion => this.convertListAndMapTemplateVariableTypes(templateVersion)));
  }

  /**
   * Converts component template variables, so that if they have list(...) or map(...) type, it will be
   * regarded as list and map (w/o the parens).
   *
   * This workaround is needed as the part of the 0.11 -> 0.12 terraform migration. It should be improved later, when we have
   * support for typed lists/maps/sets/tuples.
   */
  private convertListAndMapTemplateVariableTypes = (templateVersion: TemplateVersion): TemplateVersion => {
    const convertedInputs = templateVersion.inputs.map(componentTemplateInput => {
      let type = '';
      if (componentTemplateInput.type.startsWith('list')) {
        type = 'list';
      } else if (componentTemplateInput.type.startsWith('map')) {
        type = 'map';
      } else if (componentTemplateInput.type.startsWith('tuple')) {
        type = 'list';
      } else if (componentTemplateInput.type.startsWith('set')) {
        type = 'list';
      } else {
        type = componentTemplateInput.type;
      }

      return {
        ...componentTemplateInput,
        type: type,
      };
    });

    return {
      ...templateVersion,
      inputs: convertedInputs,
    };
  };
}
