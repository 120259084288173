import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'alp-main-view',
  styleUrls: ['./main.component.scss'],
  templateUrl: './main.component.html',
})
export class MainViewComponent implements OnInit {
  public config = [];
  public dataopsConsoleUrl = environment.dataOpsUrl;
  public helpUrl = environment.helpUrl;

  constructor() {}

  public ngOnInit(): void {}
}
