// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiEndpoint: 'https://api.devops.dev2.alpiq.io',
  menuConfigEndpoint: 'https://s3.eu-west-3.amazonaws.com/alpiq-ui-configuration-dev/menu.json',
  dataOpsUrl: 'https://modelmgmt.dataops.dev.phoenix.alpiq.io',
  helpUrl: 'https://wiki.alpiq.com/display/DTI/Guides',
  redirectUrl: 'https://onepassport.alpiq.io/login?redirect=<redirected_web_site>',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
