import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnePassportModule } from '@alpiq/one-passport';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlockModule } from '@efk3/angular-block';
import { ComponentTemplateService } from '@core/services/component-template.service';
import { DeploymentService } from '@core/services/deployment.service';
import { ProductService } from '@core/services/product.service';
import { CoreRoutingModule } from './core-routing.module';
import { environment } from '../../environments/environment';
import { AppLoader } from './route-guards';
import { MainViewComponent, NotFoundViewComponent } from './views';
import { CoreComponent } from '@core/core.component';
import { SharedModule } from '@shared/shared.module';
import { WorkflowService } from '@core/services/workflow-service';
import { WorkflowVersionService } from '@core/services/workflow-version.service';
import { SnackBarService } from '@core/services/snack-bar.service';
import { AccountConstantsService } from '@core/services/account-constants.service';
import { DeploymentEventService } from '@core/services/deployment-event.service';
import { PipesModule } from './pipes/pipes.module';
import { GlobalErrorHandler } from '@shared/error-handlers/global-error-handler';
import { MatDialogModule } from '@angular/material';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from '@shared/interceptors/http-error/http-error.interceptor';

@NgModule({
  declarations: [CoreComponent, NotFoundViewComponent, MainViewComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    BlockModule.forRoot(),
    SharedModule.forRoot(),
    OnePassportModule.forRoot({
      tokenKey: 'alpiqio_auth',
      redirectUrl: environment.redirectUrl,
      mode: environment.production ? 'cookie' : 'query-param',
      authenticatedUrlPrefix: environment.apiEndpoint,
    }),
    MatDialogModule,
    HttpClientModule,
    CoreRoutingModule, // needs to be last, otherwise every other route is 404
  ],
  exports: [FormsModule, ReactiveFormsModule, PipesModule],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    //{ provide: NetworkAuthService, useValue: { token: null, authenticated: false } },
    AppLoader,
    ProductService,
    WorkflowService,
    WorkflowVersionService,
    ComponentTemplateService,
    SnackBarService,
    DeploymentService,
    DeploymentEventService,
    AccountConstantsService,
  ],
  bootstrap: [CoreComponent],
})
export class CoreModule {}
