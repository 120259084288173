import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/interfaces/api-response';
import { Product } from '@core/interfaces/product';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class ProductService {
  private url = `${environment.apiEndpoint}/projects`;

  constructor(private httpClient: HttpClient) {}

  public list(): Observable<Product[]> {
    return this.httpClient
      .get<ApiResponse<Product[]>>(`${this.url}`, <Object>{
        limit: '999',
      })
      .pipe(map(r => r.payload));
  }

  public listByAccountId(accountId: string): Observable<Product[]> {
    return this.httpClient
      .get<ApiResponse<Product[]>>(`${this.url}`, <Object>{
        accountId: accountId,
      })
      .pipe(map(r => r.payload));
  }

  public get(id: number): Observable<Product> {
    return this.httpClient.get<Product>(`${this.url}/${id}`);
  }

  public save(product: Product): Observable<Product> {
    const data = { ...product };
    delete data.id;

    if (product.id) {
      return this.httpClient.patch<Product>(`${this.url}/${product.id}`, data);
    } else {
      return this.httpClient.post<Product>(this.url, data);
    }
  }

  public delete(id: number): Observable<Object> {
    return this.httpClient.delete(`${this.url}/${id}`);
  }

  public assignAccounts(
    projectId: number,
    accountIdsWithSapNumbers: { accounts: { accountId: number; environmentSpecificSapNumber?: string }[] }
  ): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiEndpoint}/projects/${projectId}/accounts`,
      accountIdsWithSapNumbers
    );
  }
}
