import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { DialogContent } from '@shared/services/dialog/dialog-content';
import { ConfirmationDialogConfig } from '@shared/interfaces/confirmation-dialog-config';

@Component({
  selector: 'alp-confirm-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  public afterClose: Subject<boolean> = new Subject<boolean>();
  public title: string;
  public message: string;
  public messageLines: string[];
  public useMessageLines = false;
  public confirmText: string;
  public showCancel: boolean;

  constructor(private overlayRef: OverlayRef, @Inject(DialogContent) public content: ConfirmationDialogConfig) {}

  public ngOnInit(): void {
    this.title = this.content.title;
    this.message = this.content.message;
    this.messageLines = this.content.messageLines;

    if (this.content.useMessageLines != null) {
      this.useMessageLines = this.content.useMessageLines;
    }

    this.confirmText = this.content.confirmText || 'OK';

    if (this.content.showCancel != null) {
      this.showCancel = this.content.showCancel;
    } else {
      this.showCancel = true;
    }
  }

  @HostListener('window:keyup.escape')
  public onEscape() {
    this.close(false);
  }

  public confirm() {
    this.close(true);
  }

  public close(confirmed: boolean = false) {
    this.afterClose.next(confirmed);
    this.afterClose.complete();
    this.overlayRef.dispose();
  }
}
