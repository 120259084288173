import { OnePassportAuthGuard } from '@alpiq/one-passport';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotFoundViewComponent } from './views';
import { MainViewComponent } from '@core/views';
import { AppLoader } from './route-guards';

const routes: Routes = [
  {
    path: '',
    canActivate: [AppLoader, OnePassportAuthGuard],
    component: MainViewComponent,
    children: [
      {
        path: '',
        redirectTo: 'welcome',
        pathMatch: 'full',
      },
      {
        path: 'welcome',
        loadChildren: '../welcome/welcome.module#WelcomeModule',
      },
      {
        path: 'products',
        loadChildren: '../products/products.module#ProductsModule',
      },
      {
        path: 'accounts',
        loadChildren: '../accounts/accounts.module#AccountsModule',
      },
      {
        path: 'component-templates',
        loadChildren: '../component-templates/component-templates.module#ComponentTemplatesModule',
      },
      {
        path: 'settings',
        loadChildren: '../settings/settings.module#SettingsModule',
      },
      {
        path: 'app-version',
        loadChildren: '../app-version/app-version.module#AppVersionModule',
      },
      {
        path: '**',
        component: NotFoundViewComponent,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
