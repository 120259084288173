import { HttpInterceptor, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { SnackBarService } from '@core/services/snack-bar.service';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private snackBarService: SnackBarService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        // Note that same error thrown second time won't trigger the GlobalErrorHandler
        // and this is why displayError of HttpErrorResponse is here
        this.displayError(error);
        // Rethrow the error in case other handling is needed
        return throwError(error);
      })
    );
  }

  displayError(error: HttpErrorResponse) {
    const errorMessage = this.getMessage(error);
    this.snackBarService.error(errorMessage);
  }

  getMessage(error: HttpErrorResponse) {
    // SERVER-SIDE ERRORS
    // Back-end validation errors
    if (error.error && error.error.payload && error.error.payload.message) {
      return `Operation failed. Server has returned error response. Code: ${error.error.payload.status} Message: ${
        error.error.payload.message
      }`;
    }

    // Generic HTTP errors
    if (error.message) {
      return `Operation failed. Server has returned error response. Code: ${error.status} Message: ${error.message}`;
    }

    // No error.message, should not be the case
    if (error.status === 400) {
      return `Operation failed. Make sure you entered correct values or else this was unexpected bad request 400`;
    }
    if (error.status === 403) {
      return `You are not authorized for this action or another authorization error 403 has occured.`;
    }
    if (error.status) {
      return `Operation failed. Server has returned error response. Error code: ${error.status}`;
    }
    return `Server has returned unknown error response.`;
  }
}
