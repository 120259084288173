import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '@core/interfaces/api-response';
import { DeploymentEvent } from '@core/interfaces/deployment-event';

@Injectable()
export class DeploymentEventService {
  private readonly urlEvents = `${environment.apiEndpoint}/deployment-events`;

  constructor(private httpClient: HttpClient) {}

  public listEvents(deploymentId: number, offset: number, limit: number): Observable<ApiResponse<DeploymentEvent[]>> {
    const params = new HttpParams()
      .append('deploymentId', deploymentId.toString())
      .append('offset', offset.toString())
      .append('limit', limit.toString());
    return this.httpClient.get<ApiResponse<DeploymentEvent[]>>(this.urlEvents, { params: params });
  }

  public createEvent(event: DeploymentEvent): Observable<DeploymentEvent> {
    return this.httpClient.post<DeploymentEvent>(this.urlEvents, event);
  }
}
