import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AccountConstant } from '@core/models/account-constant';
import { map } from 'rxjs/operators';
import { ApiResponse } from '@core/interfaces/api-response';

@Injectable()
export class AccountConstantsService {
  private url = `${environment.apiEndpoint}/account_constants`;

  constructor(private httpClient: HttpClient) {}

  public list(): Observable<AccountConstant[]> {
    return this.httpClient.get<ApiResponse<AccountConstant[]>>(this.url).pipe(map(r => r.payload));
  }

  public update(accountConstants: AccountConstant[]): Observable<AccountConstant[]> {
    return this.httpClient.put<ApiResponse<AccountConstant[]>>(this.url, accountConstants).pipe(map(r => r.payload));
  }
}
