import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injector,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { PortalInjector } from '@angular/cdk/portal';
import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentTypeToBeCreatedInsideModalWrapper, DialogContent } from '@shared/services/dialog/dialog-content';

@Component({
  selector: 'alp-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss'],
})
export class ModalWrapperComponent implements AfterViewInit {
  private containerReference: ComponentRef<any> = null;
  private _container: ViewContainerRef;

  @ViewChild('container', {
    read: ViewContainerRef,
  })
  set container(ref: ViewContainerRef) {
    this._container = ref;
  }

  constructor(
    public dialogRef: OverlayRef,
    @Inject(ComponentTypeToBeCreatedInsideModalWrapper) public componentType: Type<any>,
    @Inject(DialogContent) public data: any,
    private cfr: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
    private injector: Injector
  ) {}

  ngAfterViewInit(): void {
    const customTokens = new WeakMap();

    if (this.data) {
      customTokens.set(DialogContent, this.data);
    }

    const injector = new PortalInjector(this.injector, customTokens);

    const componentFactory = this.cfr.resolveComponentFactory(this.componentType);
    this.containerReference = this._container.createComponent(componentFactory, 0, injector);

    this.cdr.detectChanges();
  }

  close() {
    // If the instantiated component already has a close method, we just delegate the event to it,
    // because it might have some logic for popping up a confirmation dialog.
    if (this.containerReference.instance.close) {
      this.containerReference.instance.close();
    } else {
      this.containerReference.destroy();
      this.dialogRef.dispose();
    }
  }
}
