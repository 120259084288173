export interface Environment {
  name: string;
  value: string;
}

export namespace Environment {
  export enum Codes {
    Development = 'dev',
    Test = 'test',
    PreProduction = 'preprod',
    Production = 'prod',
  }

  export const Development: Environment = { name: 'Development', value: Codes.Development };
  export const Test: Environment = { name: 'Test', value: Codes.Test };
  export const PreProduction: Environment = { name: 'Pre-Production', value: Codes.PreProduction };
  export const Production: Environment = { name: 'Production', value: Codes.Production };

  export const All = [Development, Test, PreProduction, Production];
  export const DevAndTest = [Development, Test];

  // Be careful with the successors! Setting a circular dependency will cause an infinite loop.
  const environmentDependencies = {
    [Environment.Development.value]: {
      successor: null,
    },
    [Environment.Test.value]: {
      successor: Environment.PreProduction.value,
    },
    [Environment.PreProduction.value]: {
      successor: Environment.Production.value,
    },
    [Environment.Production.value]: {
      successor: null,
    },
  };

  export function beforeOrEqual(a: Environment, b: Environment) {
    return before(a, b) || a === b;
  }

  const environmentOrder = {};
  environmentOrder[Development.value] = 1;
  environmentOrder[Test.value] = 2;
  environmentOrder[PreProduction.value] = 3;
  environmentOrder[Production.value] = 4;

  export function before(a: Environment, b: Environment) {
    return environmentOrder[a.value] < environmentOrder[b.value];
  }

  export function get(code: string) {
    const env = All.find(e => e.value === code);
    if (!env) {
      throw new Error(`Cannot get Environment ${code} - no such environment`);
    }
    return env;
  }

  export function isEnvironmentApplicableForDeployment(targetEnvironment: string, availableEnvironments: string[]) {
    const checkEnvironmentPresentWithSuccessor = currentEnv => {
      if (availableEnvironments.indexOf(currentEnv) === -1) {
        return false;
      } else {
        if (environmentDependencies[currentEnv].successor === null) {
          return true;
        } else {
          return checkEnvironmentPresentWithSuccessor(environmentDependencies[currentEnv].successor);
        }
      }
    };

    return checkEnvironmentPresentWithSuccessor(targetEnvironment);
  }

  export function getEnvironmentUnavailableReason(targetEnvironment: string, availableEnvironments: string[]) {
    if (!isEnvironmentApplicableForDeployment(targetEnvironment, availableEnvironments)) {
      let reason = `Deployment cannot be started.`;

      if (targetEnvironment === Environment.Development.value) {
        reason += ` An account with the 'dev' environment must be linked to this product to launch this deployment.`;
      } else {
        let requiredEnvironments = [];

        if (targetEnvironment === Environment.Test.value) {
          requiredEnvironments = [
            Environment.Test.value,
            Environment.PreProduction.value,
            Environment.Production.value,
          ];
        }

        if (targetEnvironment === Environment.PreProduction.value) {
          requiredEnvironments = [Environment.PreProduction.value, Environment.Production.value];
        }

        if (targetEnvironment === Environment.Production.value) {
          requiredEnvironments = [Environment.Production.value];
        }

        reason += ` The following accounts must be linked to the product, to launch this deployment: ${requiredEnvironments.join(
          ', '
        )}`;
      }

      return reason;
    } else {
      return null;
    }
  }
}
