import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkflowVersion } from '@core/models/workflow-version';
import { HttpClient, HttpParams } from '@angular/common/http';
import { VersionContent } from '@core/models/version-content';
import { environment } from '../../../environments/environment';
import { switchMap } from 'rxjs/operators';
import { ApiResponse } from '@core/interfaces/api-response';

@Injectable({
  providedIn: 'root',
})
export class WorkflowVersionService {
  public readonly possibleConstantValues = ['string', 'bool', 'number', 'any', 'list', 'map'];

  private WORKFLOWS_VERSIONS = 'workflow-versions';
  private url = `${environment.apiEndpoint}/workflows`;

  constructor(private httpClient: HttpClient) {}

  public list(
    workflowID: number,
    page = -1,
    limit = 0,
    aggregatedData = false,
    onlyWithDeployments = false
  ): Observable<ApiResponse<WorkflowVersion[]>> {
    const params = new HttpParams()
      .append('aggregatedData', aggregatedData.toString())
      .append('onlyWithDeployments', onlyWithDeployments.toString());
    //const params: any = { aggregatedData, onlyWithDeployments };
    if (page > -1 && limit > 0) {
      params.set('offset', (page * limit).toString());
      params.set('limit', limit.toString());
    }
    return this.httpClient.get<ApiResponse<WorkflowVersion[]>>(`${this.url}/${workflowID}/${this.WORKFLOWS_VERSIONS}`, {
      params: params,
    });
  }

  public get(workflowID: number, versionID: number): Observable<WorkflowVersion> {
    return this.httpClient.get<WorkflowVersion>(`${this.url}/${workflowID}/${this.WORKFLOWS_VERSIONS}/${versionID}`);
  }

  public getLatestVersion(workflowID: number): Observable<WorkflowVersion> {
    const workflowVersion = this.list(workflowID).pipe(
      switchMap(response => {
        const versions = response.payload.map(wfv => wfv.version);
        const maxVersion = Math.max(...versions);
        return this.get(workflowID, maxVersion);
      })
    );
    return workflowVersion;
  }

  public create(workflowID: number, workflowContent: VersionContent, uiSettings: any): Observable<WorkflowVersion> {
    return this.httpClient.post<WorkflowVersion>(`${this.url}/${workflowID}/${this.WORKFLOWS_VERSIONS}`, {
      workflowContent,
      uiSettings,
    });
  }
}
